import { ref, watch, computed } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import store from '@/store';

export default function useSessionAttendance(sessionData) {
  // Use toast
  const toast = useToast();

  const refAttendanceTable = ref(null);

  // Table Handlers
  const tableColumns = [{ key: 'student.fullName', stickyColumn: true, variant: 'primary', sortable: true, label: 'Student Name' }];
  const dynamicTableColumns = [];

  const perPage = ref(10);
  const totalAttendances = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25 /* , 50, 100, 250, 500 */];
  const searchQuery = ref('');
  const sortBy = ref('profile.status');
  const isSortDirDesc = ref(false);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refAttendanceTable.value ? refAttendanceTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAttendances.value,
    };
  });

  const refetchData = () => {
    if (refAttendanceTable.value) {
      return refAttendanceTable.value.refresh();
    }
  };

  watch([currentPage, perPage, searchQuery, sessionData], () => {
    refetchData();
  });

  const fetchAttendance = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'student,profile',
    };

    store
      .dispatch('app-roster/fetchSessionAttendance', { queryParams: pl, id: sessionData.id })
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(
          results.map((result) => {
            result._rowVariant = ['terminated', 'withdrew_s1', 'withdrew_s2', 'withdrew_s3'].includes(result.profile.status)
              ? 'primary'
              : '';
            return result;
          })
        );
        totalAttendances.value = totalResults;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching session attendance',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response ? error.response.data.message : error.message,
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // const statusOptions = [
  //   { label: 'Created', value: 'created' },
  //   { label: 'Enrolling', value: 'enrolling' },
  //   { label: 'Waiting', value: 'waiting' },
  //   { label: 'Live', value: 'live' },
  //   { label: 'Completed', value: 'completed' },
  // ];

  return {
    fetchAttendance,
    tableColumns,
    dynamicTableColumns,
    perPage,
    currentPage,
    totalAttendances,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAttendanceTable,

    refetchData,

    // Extra Filters
  };
}
